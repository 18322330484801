<template>
  <div style="width: 100%; height: 100%; display: flex; flex-direction: column">
    <div style="display: flex; height: 100%">
      <div style="background: #00042d">
        <div class="logo_box">{{ language.logo }}</div>
        <div style="width: 250px; background: #00042d; height: 100%">
          <el-col>
            <el-menu
              :default-active="path"
              router
              class="el-menu-vertical-demo"
              @open="handleOpen"
              :unique-opened="true"
              background-color="#00042d"
              text-color="#fff"
              active-text-color="#ffd04b"
              active-background-color="#ffd04b"
            >
              <!-- 首页 -->
              <el-menu-item index="/" v-if="rules.homepage1 && rules.homepage2">{{
                language.homepage
              }}</el-menu-item>
              <!-- 交易中心 -->
              <el-submenu
                index="1"
                v-if="
                  rules.transactionCenter ||
                  rules.ExclusiveChannelList ||
                  rules.cashwithdrawalrecord
                "
              >
                <template slot="title">
                  <span>{{ language.Transaction_Center }}</span>
                </template>
                <!-- 交易列表 -->
                <el-menu-item v-if="rules.transactionCenter" index="/transactionCenter">{{
                  language.TransactionList
                }}</el-menu-item>
                <!-- 专属渠道 -->
                <el-menu-item
                  v-if="rules.ExclusiveChannelList"
                  index="/ExclusiveChannelList"
                  >{{ language.ExclusiveChannelList }}</el-menu-item
                >
                <!-- Cashwithdrawaldetails 提现明细 -->
                <el-menu-item
                  v-if="rules.cashwithdrawalrecord"
                  index="/cashwithdrawalrecord"
                  >{{ language.Cashwithdrawaldetails }}</el-menu-item
                >
                <!-- 商户提现明细 Merchantwithdrawaldetails -->
                <el-menu-item v-if="pid == 0" index="/Merchantwithdrawaldetails">{{
                  language.Merchantwithdrawaldetails
                }}</el-menu-item>
              </el-submenu>
              <!-- 财务中心 -->
              <el-submenu index="2" v-if="rules.dailystatement || rules.monthlystatement">
                <template slot="title">
                  <span>{{ language.financialcenter }}</span>
                </template>
                <!-- 日账单 -->
                <el-menu-item v-if="rules.dailystatement" index="/dailystatement">{{
                  language.Daily_Statement
                }}</el-menu-item>
                <!-- 月账单 -->
                <el-menu-item v-if="rules.monthlystatement" index="/monthlystatement">{{
                  language.Monthly_Statement
                }}</el-menu-item>
              </el-submenu>
              <!-- 商户提现 -->
              <el-menu-item index="/MerchantwithdrawalPage" v-if="pid == 0">{{
                language.Merchantwithdrawal
              }}</el-menu-item>
              <!-- 商户中心 -->
              <el-submenu
                index="3"
                v-if="
                  rules.merchantcenter || rules.administration || rules.PaymentManagement
                "
              >
                <template slot="title">
                  <span>{{ language.Merchant_Center }}</span>
                </template>
                <!-- 商户信息 -->
                <el-menu-item v-if="rules.merchantcenter" index="/merchantcenter">{{
                  language.MerchantInformation
                }}</el-menu-item>
                <!-- 应用管理 -->
                <el-menu-item v-if="rules.administration" index="/administration">{{
                  language.Application_Management
                }}</el-menu-item>
                <!-- 支付管理 -->
                <el-submenu
                  class="PaymentManagementbox"
                  index="3-1"
                  v-if="rules.PaymentManagement"
                >
                  <template slot="title">
                    <span>{{ language.PaymentManagement }}</span>
                  </template>
                  <el-menu-item
                    v-if="rules.PaymentManagement"
                    index="/Rechargeconfiguration"
                    >{{ language.Rechargeconfiguration }}</el-menu-item
                  >
                  <el-menu-item
                    v-if="rules.PaymentManagement"
                    index="/Withdrawalconfiguration"
                    >{{ language.Withdrawalconfiguration }}</el-menu-item
                  >
                </el-submenu>
                <!-- 银行 Bankaccountinformation -->
                <!-- v-if="rules.Merchantwithdrawal" -->
                <el-menu-item v-if="pid == 0" index="/Merchantwithdrawal">{{
                  language.Bankaccountinformation
                }}</el-menu-item>
                <!-- 角色管理 -->
                <el-menu-item v-if="pid === 0" index="/Rolemanagement">{{
                  language.Rolemanagement
                }}</el-menu-item>
                <!-- 角色管理 -->
                <el-menu-item v-if="pid === 0" index="/Administrator">{{
                  language.Administrator
                }}</el-menu-item>
              </el-submenu>
              <!-- 个人设置 -->
              <el-submenu index="4" v-if="rules.changepassword">
                <template slot="title">
                  <span>{{ language.Personalsettings }}</span>
                </template>
                <!-- 密码修改 -->
                <el-menu-item index="/changepassword">{{
                  language.Password_Settings
                }}</el-menu-item>
              </el-submenu>
              <!-- 反馈信息 feedbackinformation -->
              <el-menu-item index="/feedbackinformation">{{
                language.feedbackinformation
              }}</el-menu-item>
              <!-- 资金管理 -->
              <el-submenu index="5" v-show="false">
                <template slot="title">
                  <span>{{ language.Fundmanagement }}</span>
                </template>
                <!-- 退款列表 -->
                <el-menu-item index="/RefundRecordList">{{
                  language.RefundRecordList
                }}</el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </div>
      </div>

      <div style="flex: 1; display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            background: #00042d;
            height: 60px;
            justify-content: flex-end;
          "
        >
          <!-- 铃铛 -->
          <div
            style="
              display: flex;
              align-items: center;
              margin-right: 20px;
              position: relative;
            "
          >
            <div
              v-show="showhongdian"
              style="
                width: 9px;
                height: 9px;
                background: red;
                border-radius: 90%;
                position: absolute;
                right: 2px;
                top: 21px;
              "
            ></div>
            <img
              @click="smallbell()"
              style="width: 28px; height: 28px; cursor: pointer"
              src="../static/smallbell.png"
              alt=""
            />
          </div>
          <!-- 切换语言 -->
          <div style="display: flex; justify-content: center; align-items: center">
            <el-dropdown @command="handlanguage">
              <span class="el-dropdown-link" style="width: 78px; display: inline-block">
                {{ language.language }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="CHS">简体中文</el-dropdown-item>
                <el-dropdown-item command="TC">繁體中文</el-dropdown-item>
                <el-dropdown-item command="EN">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 右侧名字及退出登录 -->
          <div class="nav_top" style="position: relative; display: flex">
            <div>
              <img src="../static/head.jpg" alt="" />
              <!-- <img v-if="usermsg.head_img" :src="usermsg.head_img" alt=""> -->
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link" style="color: #fff">
                  {{ userinfo.nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item command="a">基础资料</el-dropdown-item>
              <el-dropdown-item command="b" style="border-bottom: 1px solid #efefef;">修改密码</el-dropdown-item> -->
                  <el-dropdown-item command="c">{{ language.Log_out }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div style="flex: 1; overflow-y: scroll; height: calc(100vh - 79px)">
          <router-view />
        </div>
        <el-dialog
          :title="language.Notice"
          :visible.sync="dialogVisible"
          width="30%"
          height="500px"
          class="Home_page"
        >
          <div
            v-show="showtype == 1"
            style="min-height: 500px; display: flex; flex-direction: column"
          >
            <div class="header">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  position: relative;
                  align-items: center;
                "
              >
                <div style="text-align: center; font-size: 20px; padding: 20px">
                  {{ language.Notice }}
                </div>
                <img
                  @click="dialogVisible = false"
                  style="width: 15px; position: absolute; right: 0; cursor: pointer"
                  src="../static/close.png"
                  alt=""
                />
              </div>
            </div>
            <div style="flex: 1">
              <div
                @click="showbtn(item, index)"
                v-for="(item, index) in tableData"
                :key="index"
                class="noticItem"
                style="border-bottom: 1px solid #efefef; padding: 10px; cursor: pointer"
              >
                <div style="display: flex; align-items: center; position: relative">
                  <div>
                    {{ item.title }}
                  </div>
                  <div
                    v-show="item.show"
                    style="
                      width: 5px;
                      height: 5px;
                      background: red;
                      border-radius: 90%;
                      margin-left: 20px;
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="paging">
              <el-pagination
                v-loading="loading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                current-page.sync="5"
                :page-sizes="[10, 15, 20, 25, 30]"
                :page-size="limit"
                element-loading-background="rgb(26 27 51 / 80%)"
                layout="sizes, prev, pager, next"
                :total="pages"
              >
              </el-pagination>
            </div>
          </div>
          <div
            v-show="showtype == 2"
            style="min-height: 500px; display: flex; flex-direction: column"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                position: relative;
                align-items: center;
              "
            >
              <img
                @click="backbtn()"
                style="width: 15px; position: absolute; left: 0; cursor: pointer"
                src="../static/back.png"
                alt=""
              />
              <div style="text-align: center; font-size: 20px; padding: 20px">
                {{ noticemsg.title }}
              </div>
              <img
                @click="dialogVisible = false"
                style="width: 15px; position: absolute; right: 0; cursor: pointer"
                src="../static/close.png"
                alt=""
              />
            </div>
            <div style="margin-top: 10px; text-align: center">
              {{ noticemsg.update_time }}
            </div>
            <div v-html="noticemsg.content" style="flex: 1; overflow: auto"></div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import rules from "../common/js/rules";
import { info, user, groupgetRuleTreeList, indexnotice } from "../api/index.js";
export default {
  data() {
    return {
      dialogVisible: false,
      usermsg: {
        username: "",
      },
      showurl: "/",
      language: null,
      path: "",
      userinfo: {
        nickname: "",
      },
      tableData: [],
      page: 1,
      limit: 10,
      pages: null,
      showtype: 1,
      pid: "",
      rules: "",
      loading: false,
      noticemsg: "",
      indexnoticeid: "",
      showhongdian: false,
    };
  },
  watch: {
    $route() {
      this.showurl = this.$route.meta;
    },
    "$store.state.Refresh"() {
      this.getinfo();
      this.path = this.$route.path;
    },
    "$store.state.rules"() {
      this.rules = rules.rules().msg;
    },
    dialogVisible() {
      if (this.dialogVisible == false) {
        this.indexnoticeid2 = localStorage.getItem("indexnoticeid");
      }
    },
  },
  created() {
    this.indexnotice2();
    this.changelanguage();
    this.showurl = this.$route.meta;
    if (JSON.parse(localStorage.getItem("userinfo"))) {
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    }
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
      return;
    }
    this.getinfo();
    this.path = this.$route.path;
  },
  methods: {
    // 编译标签
    chengemsg(e) {
      // eslint-disable-next-line no-unused-vars
      var msg = "";
      msg = e.replace(/&amp;/g, "&");
      msg = msg.replace(/&lt;/g, "<");
      msg = msg.replace(/&gt;/g, ">");
      msg = msg.replace(/&nbsp;/g, " ");
      msg = msg.replace(/&quot;/g, '"');
      return msg;
    },
    showbtn(e, i) {
      this.tableData[i].show = false;
      this.indexnoticeid2 = this.indexnoticeid2 + e.id + ",";
      this.noticemsg = e;
      this.noticemsg.content = this.chengemsg(this.noticemsg.content);
      this.showtype = 2;
    },
    smallbell() {
      // console.log(localStorage.getItem("indexnoticeid"));
      localStorage.setItem("indexnoticeid", this.indexnoticeid);
      this.showhongdian = false;
      this.dialogVisible = true;
      this.tableData = [];
      this.showtype = 1;
      this.indexnotice();
    },
    backbtn() {
      this.showtype = 1;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.indexnotice();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pages = 1;
      this.limit = val;
      this.indexnotice();
    },
    indexnotice() {
      let data = {
        page: this.page,
        limit: this.limit,
      };
      indexnotice(data).then((res) => {
        this.loading = false;
        this.pages = res.data.count;
        if (this.indexnoticeid2) {
          this.indexnoticeidlist = this.indexnoticeid2.split(",");
        }
        this.tableData = res.data.list;
        if (this.indexnoticeidlist) {
          this.tableData.map((item) => {
            if (this.indexnoticeidlist.includes(String(item.id))) {
              item.show = false;
            } else {
              item.show = true;
            }
          });
        }
      });
    },
    indexnotice2() {
      let data = {
        page: 1,
        limit: 10,
      };
      indexnotice(data).then((res) => {
        let data2 = {
          page: 1,
          limit: res.data.count,
        };
        indexnotice(data2).then((res2) => {
          let idlist = "";
          res2.data.list.map((item) => {
            idlist = idlist + item.id + ",";
          });
          this.indexnoticeid2 = localStorage.getItem("indexnoticeid");
          if (localStorage.getItem("indexnoticeid") != idlist) {
            this.showhongdian = true;
          }
          this.indexnoticeid = idlist;
        });
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    // 获取是否绑定
    getinfo() {
      user().then((res) => {
        localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
        this.pid = res.data.userinfo.pid;
        if (this.pid == 0) {
          info().then((res) => {
            if (res.code == 1) {
              localStorage.setItem("info", JSON.stringify(res.data));
              if (!res.data) {
                this.$store.commit("changeinfo", true);
                this.$router.push("/merchantcenter");
              } else {
                if (res.data.status == 1 || res.data.status == 3) {
                  console.log(111);
                  this.$store.commit("changeinfo", true);
                  this.$router.push("/merchantcenter");
                }
              }
            }
          });
        }
        localStorage.setItem("pid", this.pid);
        if (res.data.userinfo.group_id > 0) {
          let params = {
            groupId: res.data.userinfo.group_id,
          };
          groupgetRuleTreeList({}, params).then((res2) => {
            localStorage.setItem("adminNode", JSON.stringify(res2.data));
            this.rules = rules.rules().msg;
          });
        } else {
          this.rules = rules.rules().msg;
        }
      });
    },
    //获取配置语言
    changelanguage() {
      this.language = this.$units();
      document.title = "EPAY" + this.language.Merchantbackend;
    },
    // 顶部跳转
    gourl(e) {
      this.$router.push(e);
      localStorage.setItem("showurl", e);
    },
    main() {},
    // 语言切换
    handlanguage(command) {
      if (command == "CHS") {
        this.language = "简体中文";
        this.$i18n.locale = "CHS";
      } else if (command == "TC") {
        this.language = "繁體中文";
        this.$i18n.locale = "TC";
      } else if (command == "EN") {
        this.language = "English";
        this.$i18n.locale = "EN";
      }
      this.$store.commit("changelanguage", command);
      this.changelanguage();
      // this.$message('click on item ' + command);
    },
    // 退出登录
    handleCommand(command) {
      if (command == "b") {
        // this.$router.push("/ChangePassword")
        //ChangePassword
      } else if (command == "a") {
        // this.$router.push("/modifydata")
      } else if (command == "c") {
        this.$store.commit("changeinfo", false);
        window.localStorage.clear();
        this.$router.push("/Login");
      }
    },
  },
};
</script>
<style scoped>
.logo_box {
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.nav_top > div {
  height: 100%;
}
.noticItem:hover {
  color: #2196f3;
}

.nav_top > div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;
  box-sizing: border-box;
}

.nav_top > div > img {
  width: 40px;
  height: 40px;
  border-radius: 90%;
  margin-right: 20px;
}

.nav_top {
  padding: 0 20px;
  cursor: pointer;
}

* /deep/ .el-dialog__body {
  padding: 0 20px;
}
.Home_page /deep/ .el-dialog__header {
  display: none;
}
.act {
  color: #fff;
  border-bottom: 3px solid #efefef;
  padding-bottom: 4px;
}

.noact {
  color: #fff;
  border-bottom: 3px solid #efefef00;
  padding-bottom: 4px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-menu {
  border-right: none;
}
* /deep/.el-menu .el-menu-item:hover {
  outline: 0 !important;
  background: linear-gradient(270deg, #4d5070 0%, #4d5070 100%) !important;
}

* /deep/.el-menu .el-menu-item.is-active {
  background: linear-gradient(270deg, #4d5070 0%, #4d5070 100%) !important;
}

* /deep/ .el-submenu .el-menu-item {
  background: #1d2464 !important;
}

.el-submenu /deep/.el-submenu__title:hover {
  background: linear-gradient(270deg, #4d5070 0%, #4d5070 100%) !important;
}
/* * /deep/ .el-input__inner {
  padding: 0px !important;
} */
.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.PaymentManagementbox /deep/ .el-submenu__title {
  background: #1d2464 !important;
}
</style>
